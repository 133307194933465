<template>
	<v-card>
		<s-toolbar
			label="Ingreso de Maquila"
			dark
			:color="'#BAB6B5'"
			close
			@close="closeEnterMaquila()"
			class="mb-6"
			save
		></s-toolbar>
		<v-container>
			<v-row>
				<v-col>
					<s-date
						label="Fecha Recepción"
					></s-date>
				</v-col>
				<v-col>
					<s-text readonly label="Productor" v-model="lotReceptionSave.PrdCardName"></s-text>
				</v-col>
				<v-col>
					<s-text readonly label="Fundo" v-model="lotReceptionSave.FagName"></s-text>
				</v-col>
				<v-col>
					<s-text readonly label="Lote" v-model="lotReceptionSave.FltName"></s-text>
				</v-col>
				<v-col>
					<s-text label="Cultivo" readonly v-model="lotReceptionSave.TypeCultiveName" />
				</v-col>
			</v-row>
			<v-row>
				<v-col>
					<s-text label="Tipo Cultivo" readonly v-model="lotReceptionSave.TypeCropName" />
				</v-col>
				<v-col>
					<s-text v-model="lotReceptionSave.VrtName" readonly label="Variedad" />
				</v-col>
				<v-col>
					<s-text
						v-model="lotReceptionSave.RcfNumberReferralGuide"
						label="Guía Remisión"
					></s-text>
				</v-col>
				<v-col>
					<s-text
						v-model="lotReceptionSave.RcfWeightGuide"
						label="Peso Cliente"
						decimal
					></s-text>
				</v-col>
				<v-col>
					<s-text
						v-model="lotReceptionSave.WbbGrossWeight"
						label="Peso Planta"
						decimal
					></s-text>
				</v-col>
			</v-row>
			<v-row>
				<v-col>
					<s-text
						v-model="Discount"
						label="Diferencia"
						decimal
					></s-text>
				</v-col>
				<v-col>
					<s-text
						v-model="DiscountPercentage"
						label="Diferencia(%)"
						decimal
					></s-text>
				</v-col>
				<v-col>
					<s-text
						label="Descarte"
						decimal
					></s-text>
				</v-col>
				<v-col>
					<s-select-definition
						v-model="objTypeCultive"
						label="Cultivo"
						return-object
						:def="1173"
						@getDataInitial="getDataInitial($event)"
						@input="onchangeCultive($event)"
					>

					</s-select-definition>
				</v-col>
				<v-col>
					<s-select-definition
						:dgr="groupID"
						:def="1326"
						
						@change="change($event)"
					>
					</s-select-definition>
				</v-col>
			</v-row>
			
		</v-container>
	</v-card>
</template>

<script>
	export default {

		props: {
			lotReception: null,
		},

		data() {
			return {
				lotReceptionSave : {},
				objLot: {},
				objTypeCultive: {},
				groupID: 0,
				itemsTypeCultive: []
			}
		},

		watch: {
			"lotReception.TypeCultive"() {
				this.lotReceptionSave = [];
				this.lotReceptionSave = this.lotReception;
				let TypeCultive = this.itemsTypeCultive.filter(x => 
					x.DedValue == this.lotReception.objTypeCultive
				);
				console.log("cultivo selected ", TypeCultive);
			}
		},

		computed: {
			Discount() {
				let disc = (this.lotReceptionSave.RcfWeightGuide - this.lotReceptionSave.WbbGrossWeight);
				if(disc < 0  )
				{
					this.$fun.alert("Error en la diferencia", "warning");
					return;
				}
				return disc.toFixed(2);
			},

			DiscountPercentage()
			{
				let percentage = (this.Discount / this.lotReceptionSave.RcfWeightGuide ) * 100;
				if(percentage <= 0)
				{
					this.$fun.alert("Error en la diferencia porcentual", "warning");
					return;
				}
				return percentage.toFixed(2);
			}
		},

		methods: {
			onchangeCultive(item){
				console.log("item del cultive", this.objTypeCultive)
			},
			getDataInitial(items)
			{
				this.itemsTypeCultive = items;
			},

			closeEnterMaquila() {
				this.$emit("closeEnterMaquila");
			},

			change(items)
			{
				console.log('itemsss ', items);
			}
			
		},

		created  () {
			this.lotReceptionSave = [];
			this.lotReceptionSave = this.lotReception;
			//this.objTypeCultive = this.lotReception.TypeCultive;
			//this.objTypeCultive.DedValue = 2;

			this.itemsTypeCultive.forEach(element => {
				/*if(element.DedDescription == this.lotReception.TypeCultive){

				}*/
					console.log("x.DedDescription", element.DedDescription)
			}); 

			//console.log('wwww samir', TypeCultive)

		},
	}
</script>

<style lang="scss" scoped>

</style>