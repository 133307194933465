<template>
	<div>
		<s-toolbar
			label="Subida a SAP"
			dark
			:color="'#BAB6B5'"
			close
			save
			@save="savePre()"
			:load="lotReception.RcfStatusSap == 1 || lotReception.RcfState == 11"
			@load="save()"
			@close="closeDialogLoadSap()"
			class="mb-6"
			sync
			@sync="Sincronizar()"
		></s-toolbar>
		<v-col>
			<v-card :disabled="lotReception.RcfStatusSap == 2">
			<v-row>
				<v-col cols="12" lg="12" md="12" sm="12">
					<v-card>
						<s-toolbar 
							label="Información General"
							color="#E0F7F2"
						></s-toolbar>
						<v-container>
							<v-row>
								<v-col cols="2" lg="2" md="2" sm="2">
									<s-text
										v-model="lotReception.RcfID"
										readonly
										label="N° Lote"
									></s-text>
								</v-col>
								<v-col cols="2" lg="2" md="2" sm="2">
									<s-text
										v-model="lotReception.PrfYuliano"
										readonly
										label="Yuliano"
									></s-text>
								</v-col>
								<v-col cols="2" lg="2" md="2" sm="2">
									<s-text
										v-model="lotReception.TypeServiceName"
										readonly
										label="Tipo Servicio"
									></s-text>
								</v-col>
								<v-col cols="2" lg="2" md="2" sm="2">
									<s-text
										v-model="lotReception.TypeOriginName"
										readonly
										label="Procedencia"
									></s-text>
								</v-col>
								<v-col cols="4" lg="4" md="4" sm="4">
									<s-text
										v-model="lotReception.FagName"
										readonly
										label="Fundo"
									></s-text>
								</v-col>
							</v-row>
							<v-row>
								<v-col cols="2" lg="2" md="2" sm="2">
									<s-text
										v-model="lotReception.TypeCropName"
										readonly
										label="Tipo Cultivo"
									></s-text>
								</v-col>
								<v-col cols="2" lg="2" md="2" sm="2">
									<s-text
										v-model="lotReception.TypeCultiveName"
										readonly
										label="Cultivo"
									></s-text>
								</v-col>
								<v-col cols="2" lg="2" md="2" sm="2">
									<s-text
										v-model="lotReception.VrtName"
										readonly
										label="Variedad"
									></s-text>
								</v-col>
								<v-col cols="2" lg="4" md="2" sm="2">
									<s-text
										v-model="lotReception.PrdCardNameResponsable"
										readonly
										label="Responsable Por Cobrar"
									></s-text>
								</v-col>
								<v-col
									v-if="this.$fun.hasSpecialPermission('uploadsap')"
									cols="2" lg="4" md="2" sm="2">
									<s-text
										v-model="lotReception.PrdCardNameTrue"
										readonly
										label="Productor"
									></s-text>
								</v-col>
							</v-row>
						</v-container>
					</v-card>
				</v-col>
			</v-row>
			
			<v-row>
				<v-col cols="8" lg="8" md="8" sm="6">
					<v-card>
						<s-toolbar 
							label="Documento de entrada"
							color="#E0F7F2"
						></s-toolbar>
						<v-container>
							<v-row>
								<v-col>
									<s-select-definition
										v-model="ObjTypeEntryDocument"
										label="Documento entrada"
										:def="1365"
										return-object
										@input="input($event)"
									></s-select-definition>
								</v-col>
								<v-col>
									<s-date
										v-model="lotReception.DateDocument"
										label="Fecha"
									></s-date>
								</v-col>
								<v-col>
									<s-text
										v-model="lotReception.Serie"
										label="Serie"
										readonly
									></s-text>
								</v-col>
								<v-col>
									<s-text
										v-model="lotReception.Issue"
										label="Nro"
									></s-text>
								</v-col>
							</v-row>
							<v-row>
								<v-col>
									<s-text
										v-model="lotReception.AmountJabasWeigh"
										readonly
										label="Cantidad Jabas"
									></s-text>
								</v-col>
								<v-col>
									<s-text
										v-model="lotReception.RcfWeightGuide"
										label="Peso Guía"
									></s-text>
								</v-col>
								<v-col>
									<s-text
										v-model="lotReception.WbbNetWeight"
										readonly
										label="Peso Neto"
									></s-text>
								</v-col>
								
								<v-col>
									<s-text
										v-model="lotReception.discart"
										label="Descarte"
										decimal
										
									></s-text>
									<!-- :readonly="lotReception.TypeProcessingPlant != 2" -->
								</v-col>
							</v-row>
							<v-row>
								
								<v-col>
									<s-text
										v-model="lotReception.percentajeDiscart"
										label="% Descarte"
										decimal
										readonly
									></s-text>
								</v-col>
								<v-col>
									<s-text
										v-model="lotReception.RcfWeightReturn"
										label="Devolución"
										decimal
										readonly
									></s-text>
								</v-col>
								<v-col>
									<s-text
										v-model="lotReception.percentajeReturn"
										label="% Devolución"
										decimal
										readonly
									></s-text>
								</v-col>
								<v-col v-if="lotReception.TypeProcessingPlant == 2">
									<s-text
										v-model="lotReception.RcfYulianoExt"
										label="Yuliano"
									></s-text>
								</v-col>
							</v-row>
						</v-container>
					</v-card>
				</v-col>
				<v-col cols="4" lg="4" md="4" sm="12">
					<v-card>
						<s-toolbar 
							label="Lote"
							color="#E0F7F2"
						></s-toolbar>
						<v-container>
							<v-row>
								<v-col v-if="this.$fun.isAdmin()">
									<s-switch
										v-model="lotReception.IgvCheck"
										label="IGV"
									></s-switch>
								</v-col>
								<v-col >
									<s-select-definition
										label="tipo peso"
										:def="1377"
										v-model="lotReception.TypeWeight"
										@input="changeTypeWeight(lotReception)"
									></s-select-definition>
								</v-col>
							</v-row>
							<v-row>
								<v-col>
									<s-text
										:label="lotReception.TypeWeight == 1 ? 'Peso Lavado' : '' "
										v-model="weigthSelectedReal"
										:readonly="weigthSelectedRealreadonly"
										decimal
									></s-text>
								</v-col>
							</v-row>
							<!-- <v-row v-if="lotReception.TypeWeight == 1">
								<v-col>
									<s-text
										label="Peso Lavado"
										v-model="lotReception.RcfWeightWash"
										readonly
										decimal
									></s-text>
								</v-col>
							</v-row> -->
						</v-container>
					</v-card>
				</v-col>
				
			</v-row>
			</v-card>
		</v-col>
		<v-dialog v-model="processingSAP" :persistent="processingSAP" width="400">
		<v-card color="primary" dark>
			<v-card-text >
			Por favor espere
			<v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
			</v-card-text>
				
		</v-card>
	</v-dialog>
	</div>
	
</template>

<script>
	import _sReceptionFrozenService from "@/services/FrozenProduction/ReceptionFrozenService.js"

	export default {
		props: {
			lotReception: null,
		},

		data() {
			return {
				processingSAP: false,
				listPricesSap: [],
				objTypeCurrency: {},
				typeweights: [],
				ckeckWeigth: false,
				ObjTypeEntryDocument: {},
				weigthSelectedReal: "",
				weigthSelectedRealreadonly: true,
				lotReceptionSave: {}
			}
		},

		watch: {
			"lotReception.ListNum"() {
				this.getPrice();
			},

			typeweights()
			{
				
			}
		},

		methods: {
			initialize()
			{
		 
				_sReceptionFrozenService
				.searchbyid(this.lotReception, this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200)
					{
						if(resp.data != null)
						{
							console.log('datos que me traen de recepcion david', resp.data)
							this.lotReception.Issue = resp.data.RfsIssue;
							this.lotReception.IgvCheck = resp.data.RfsIgv;
							this.ObjTypeEntryDocument.DedHelper = resp.data.RfsSerie;
							this.ObjTypeEntryDocument.DedValue = resp.data.TypeEntryDocument;
							this.weigthSelectedReal = resp.data.RfsWeigth;
							this.lotReception.TypeWeight = resp.data.TypeWeight;
							this.lotReception.DateDocument = resp.data.RfsDocumentDate;
							this.lotReception.TypeEntryDocument = resp.data.TypeEntryDocument;
							this.lotReception.Serie = resp.data.Serie;
							this.lotReception.RfsID = resp.data.RfsID;
							this.lotReception.RcfYulianoExt = resp.data.RcfYulianoExt;
							if(resp.data.RfsWeightDiscart != null)
							{
								this.lotReception.discart = resp.data.RfsWeightDiscart;
							}
							
							//this.lotReception.Issue = resp.data.Issue;
						}
					}
				})
			},

			getPrice()
			{
				let obj = {
					PriceList: this.lotReception.ListNum,
					TypeCultive: this.lotReception.TypeCultive,
					TypeCrop: this.lotReception.TypeCrop,
					VrtID: this.lotReception.VrtID,
				};
				_sReceptionFrozenService
				.getprice(obj, this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200){
					}
				})
			},

			Sincronizar(){
				
				//validar si esta en SAP	
				console.log("this.lotReception.", this.lotReception);
				if (this.lotReception.RcfID == "" || this.lotReception.RcfID == null) {
					this.$fun.alert("No se ha seleccionado Lote", "warning");
					return;
				}
				 
				this.processingSAP = true;
				_sReceptionFrozenService
					.verifLoadsap(this.lotReception, this.$fun.getUserID())
					.then(resp => {
						this.processingSAP = false;
						console.log("resp", resp);
						if(resp.status == 200){
							this.$fun.alert("Se Sincronizo Correctamente", "success")
							this.closeDialogLoadSap();
						}
					},(e) => {
						this.processingSAP = false;
						console.log("eeeeeee",  e.response.data.Message);

					}
					
					)
				 

			},

			save() {

				if(this.lotReception.RcfStatusSap == 2)
				{
					this.$fun.alert("El lote ya esta cargado a SAP");
					return;
				}
				this.$fun.alert("¿Seguro de subir a SAP?", "question")
				.then(r => {
					if(r.value){
						console.log('dddddddddd ', this.lotReception.TypeEntryDocument);
						if(this.lotReception.TypeEntryDocument == null)
						{
							this.$fun.alert("Ingrese tipo de documento de entrada");
							return;
						}
						if(this.lotReception.Serie == "" || this.lotReception.Serie == 0)
						{
							this.$fun.alert("Ingrese Serie");
							return;
						}
						if(this.lotReception.Issue == "" || this.lotReception.Issue == 0)
						{
							this.$fun.alert("Ingrese número");
							return;
						}
						if(this.lotReception.RcfWeightGuide == "" || this.lotReception.RcfWeightGuide == 0){
							this.$fun.alert("Ingrese Peso Guía");
							return;
						}
						this.lotReception.WbbNetWeight = this.weigthSelectedReal;
						this.lotReception.TypeDocument = this.ObjTypeEntryDocument.DedValue;
						/* this.lotReception.TypeCurrency = this.objTypeCurrency.DedValue;
						this.lotReception.TypeCurrencyHelper = this.objTypeCurrency.DedHelper; */
						_sReceptionFrozenService
						.loadsap(this.lotReception, this.$fun.getUserSAPInfo().UsrID, this.$fun.getUserID())
						.then(resp => {
							if(resp.status == 200){
								this.$fun.alert("Subido Correctamente", "success")
							}
						})
					}
				})

			},

			closeDialogLoadSap()
			{
				this.$emit("closeDialogLoadSap");
			},

			getDataInitialTypeWeigths(items)
			{
				this.typeweights = items;
			},

			input(item)
			{
				this.lotReception.Serie = item.DedHelper;
			},

			changeTypeWeight(lotReception)
			{
				this.weigthSelectedRealreadonly = true;
				let discartOld = lotReception.discart;
				
				console.log('dddd' , lotReception);
				if(lotReception.TypeWeight == 1)
				{
					this.weigthSelectedReal = lotReception.RcfWeightWash;
				}
				if(lotReception.TypeWeight == 1 && lotReception.RcfWeightGuide != 0){
					lotReception.discart = 0;
					this.lotReception.discart = parseFloat((this.lotReception.RcfWeightGuide - this.lotReception.RcfWeightWash)).toFixed(2)
				}
				if(lotReception.TypeWeight == 2)
				{
					this.weigthSelectedReal = lotReception.WbbNetWeight;
				}
				if(lotReception.TypeWeight == 3)
				{
					this.weigthSelectedReal = lotReception.RcfWeightTicket;
				}
				if(lotReception.TypeWeight == 4)
				{
					this.weigthSelectedReal = lotReception.RcfWeightWash;
				}
				if(lotReception.TypeWeight == 5)
				{
					this.weigthSelectedReal = this.weigthSelectedReal;
					this.weigthSelectedRealreadonly = false;
				}
			},

			savePre()
			{
				if(this.lotReception.RcfStatusSap == 2)
				{
					this.$fun.alert("El lote ya esta cargado a SAP");
					return;
				}
				this.$fun.alert("¿Seguro de guardar?", "question")
				.then(r => {
					if(r.value)
					{
						if(this.ObjTypeEntryDocument.DedValue == undefined)
						{
							this.$fun.alert("Seleccione el documento de entrada", "warning");
							return;
						}
						if(this.lotReception.Serie == null || this.lotReception.Serie == "")
						{
							this.$fun.alert("Ingrese la serie", "warning");
							return;
						}
						if(this.lotReception.Issue == null || this.lotReception.Issue == "")
						{
							this.$fun.alert("Ingrese el número", "warning");
							return;
						}

						this.lotReceptionSave = this.lotReception;
						this.lotReceptionSave.RfsDocumentDate = this.lotReception.DateDocument;
						this.lotReceptionSave.TypeEntryDocument = this.ObjTypeEntryDocument.DedValue;
						this.lotReceptionSave.RfsSerie = this.lotReception.Serie;
						this.lotReceptionSave.RfsIssue = this.lotReception.Issue;
						this.lotReceptionSave.RfsIgv = this.lotReception.IgvCheck;
						this.lotReceptionSave.RfsWeigth	 = this.weigthSelectedReal;
						this.lotReceptionSave.UsrCreateID	 = this.$fun.getUserID();
						this.lotReceptionSave.UsrUpdateID	 = this.$fun.getUserID();
						this.lotReceptionSave.RfsWeigthGuide = this.lotReception.RcfWeightGuide;
						this.lotReceptionSave.RfsWeightDiscart = this.lotReception.discart;
						this.lotReceptionSave.RcfYulianoExt = this.lotReception.RcfYulianoExt;
						
						_sReceptionFrozenService
						.saveuploadsap(this.lotReceptionSave, this.$fun.getUserID())
						.then(resp => {
							if(resp.status == 200)
							{
								this.$fun.alert("Guardado Correctamente", "success");
							}
						})
					}
				})
				
			}
		},

		created () {
			console.log("created");
			console.log("this.lotReception mounted", this.lotReception);
			this.initialize();

		},

		mounted () {
			this.getDataInitialTypeWeigths();
			this.lotReception.discart = (this.lotReception.WbbNetWeight - this.lotReception.RcfWeightWash).toFixed(2);
			this.lotReception.percentajeDiscart = ((this.lotReception.discart / this.lotReception.WbbNetWeight) * 100).toFixed(2);
			this.lotReception.percentajeReturn = ((this.lotReception.percentajeReturn / this.lotReception.WbbNetWeight) * 100).toFixed(2);
			this.lotReceptionSave = this.lotReception;

			
		},
	}
</script>