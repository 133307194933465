<template>
  <div>
    <v-card>
      <v-container>
        <v-row>
          <v-col cols="3"
            ><v-switch v-model="ViewZonLocation" @click="load()"
              >Por Zona</v-switch
            ></v-col
          >
          <v-col cols="3"
            ><s-date
              v-model="filter.cDateInitial"
              label="Fecha Inicio"
              :disabled="filter.TrnID > 0 ? true : false"
            ></s-date
          ></v-col>
          <v-col cols="3"
            ><s-date
              v-model="filter.cDateFin"
              label="Fecha Fin"
              :disabled="filter.TrnID > 0 ? true : false"
            ></s-date
          ></v-col>
          <!--ocultar por obs-->
          <v-col style="display: none;" cols="3"
            ><s-turn-in-process
              :TypeArea="1"
              clearable
              v-model="filter.TrnID"
            ></s-turn-in-process
          ></v-col>
        </v-row>
        <v-row>
          <!-- <v-col :cols="ViewTotal==true ? 4 : 12"><v-switch v-model="ViewTotal" @click="load()" label="Por Lote Prod."></v-switch></v-col>
					<v-col v-if="ViewTotal" cols="8"><s-text label="N° Lote de Producción" v-model="RcfID" @input="inputLote($event)" ></s-text></v-col>
					 -->

          <v-col cols="12" lg="12">
            <v-row>
              <h3 class="mt-3 ml-6">KG RECEPCION</h3>
              <v-spacer> </v-spacer>
            </v-row>

            <v-row>
              <v-col class="pb-0">
                <v-divider></v-divider>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                lg="6"
                md="6"
                v-for="item in Object.keys(itemsKg)"
              >
                <v-row>
                  <h3 class="mt-3 ml-6">{{ item }}</h3>
                  <v-spacer> </v-spacer>
                </v-row>

                <v-row>
                  <v-col class="pb-0">
                    <v-divider></v-divider>
                  </v-col>
                </v-row>

                <v-col lg="12" class="pt-0">
                  <v-list v-for="d in itemsKg[item]">
                    <v-list-item>
                      <v-list-item-avatar>
                        <v-btn small elevation="0" fab dark color="info">
                          <b
                            ><h2>
                              {{ d.WpbQuantityParihuela }}
                            </h2></b
                          >
                        </v-btn>
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title>
                          <h1>
                            {{ d.WpbWeightNet }}
                            <b style="font-family: Courier; font-size: 18px">
                              KG</b
                            >
                          </h1>
                          <b style="color: blue"></b>
                        </v-list-item-title>

                        <v-list-item-subtitle v-if="ViewZonLocation">
                          <b
                            ><h2>{{ d.TypeZonLocationName }}</h2></b>
                          <b
                            ><h2>{{ d.TypeCaliberName }}</h2></b>
                          <b
                            style="
                              text-transform: uppercase;
                              -webkit-text-stroke: thin;
                            "
                            >{{ d.TypeCropName }} - {{ d.VrtName }}</b
                          ><br />
                          <b style="text-transform: uppercase"
                            >{{ d.WpbJabasQuantity }} -
                            {{ d.WpbWeightGross }} PB</b
                          >
                        </v-list-item-subtitle>
                        <v-list-item-subtitle v-if="!ViewZonLocation">
                          <b
                            ><h2>{{ d.TypeCaliberName }}</h2></b
                          >
                          <b
                            style="
                              text-transform: uppercase;
                              -webkit-text-stroke: thin;
                            "
                            >{{ d.TypeCropName }} - {{ d.VrtName }}</b
                          ><br />
                          <b style="text-transform: uppercase"
                            >{{ d.WpbJabasQuantity }} -
                            {{ d.WpbWeightGross }} PB</b
                          >
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <!-- <v-btn
										x-small
										elevation="0"
										fab
										dark
										color="error"
									>
										<v-icon style="font-size: 20px !important">
											fas fa-times
										</v-icon>
									</v-btn> -->
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-col>

              <!-- <v-col cols="12" v-if="!ViewTotal">
									<b>Leyenda: </b>
									<v-chip color="error" x-small>Abast. Empaque</v-chip><v-chip color="info" x-small>Sin Abastecer</v-chip>
							</v-col> -->

              <v-divider vertical></v-divider>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import _sReception from "@/services/FreshProduction/ReceptionFreshService.js";
import sTurnInProcess from "@/components/FreshProduction/Turn/sTurnInProcess.vue";
export default {
  components: { sTurnInProcess },
  data() {
    return {
      filter: {},
      itemsKg: [],
      ViewZonLocation: false,
    };
  },
  watch: {
    "filter.cDateInitial"() {
      this.load();
    },
    "filter.cDateFin"() {
      this.load();
    },
    "filter.TrnID"() {
      if (this.filter.TrnID > 0) {
        this.filter.cDateInitial = null;
        this.filter.cDateFin = null;
      }

      this.load();
    },
  },
  methods: {
    load() {
      _sReception
        .GetKgReceptionFrozen(
          {
            TrnID: this.filter.TrnID,
            ViewZonLocation: this.ViewZonLocation,
            cDateInitial: this.filter.cDateInitial,
            cDateFin: this.filter.cDateFin,
          },
          this.$fun.getUserID()
        )
        .then((resp) => {
          if (resp.status == 200) {
            let items = resp.data;
            this.itemsKg = _.groupBy(items, "TypeProcessingPlantName");

            console.log(this.itemsKg);
          }
        });
    },
  },
  created() {
    this.load();
  },
};
</script>

<style>
</style>