<template>
    <div >
        <v-card>
        <v-row>
            <v-col cols="4" class="s-col-form">
				<!-- -->
				<v-card dense outlined  :disabled="lotReception.RcfStatusSap == 2">
					<s-toolbar label="1.- Información Recepción" color="#E0F7F2"></s-toolbar>
					<v-card-text>
						<v-row>
							<v-col class="s-col-form">
								<h4>
									{{ "Peso Guía" }}
								</h4>
							</v-col>
							<v-col class="s-col-form">
								<h4>
									{{ lotReception.RcfWeightGuide | numberDefaultKg }}
								</h4>
							</v-col>
							<v-col class="s-col-form">
								<v-label>
									{{ "-" }}
								</v-label>
							</v-col>
						</v-row>
						<v-row>
							<v-col class="s-col-form">
								<h4>
									{{ "Peso Neto" }}
								</h4>
							</v-col>
							<v-col class="s-col-form">
								<h4>
									{{ lotReception.WbbNetWeight | numberDefaultKg }}
								</h4>
							</v-col>
							<v-col class="s-col-form">
								<v-label>
									{{ "-" }}
								</v-label>
							</v-col>
						</v-row>
						<v-row>
							<v-col class="s-col-form">
                                <!-- :style="colorText" -->
								<h4 >
									{{ "Diferencia "  }}
								</h4>
							</v-col>
							<v-col class="s-col-form">
								<h4>
									{{ lotReception.infRefDifference | numberDefaultKg }}
								</h4>
							</v-col>
							<v-col class="s-col-form">
                                <!-- :style="colorText" -->
								<h4 >
									{{ lotReception.infRefDifferencePercentage | numberDefault }} %
								</h4>
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>
			</v-col>
            <v-col cols="8"   >
                 <v-row>
                    <v-col cols="4">
                        <s-text
                            label="Peso Final aceptable Alm. 02"
                            v-model.number="lotReception.RfsWeigth"
                            
                        />
                    </v-col>
                    <v-col cols="4">
                        <s-text
                            label="Merma aceptable Alm. 20"
                            v-model.number="lotReception.RfsWeightDiscart"
                            
                        />
                    </v-col>
                    <v-col cols="4">
                        <s-text
                            label="Peso a Favor"
                            v-model.number="lotReception.RfsWeigthNotSap"
                            
                        />
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
       
    </v-card>

    </div>
    

</template>

<script>
	export default {
		props: {
			lotReception: {}
		},

		data() {
			return {
				// lotReception: {},
				 
			}
		},
        mounted(){
            console.log("this.lotReception", this.lotReception)
        }
    }
</script>