<template>
  <div>
    <!-- <v-toolbar class="elevation-0" dark color="primary" height="40">
			
			<h2>MUESTREO</h2>
			<v-spacer></v-spacer>

			<v-btn icon dark @click="$emit('close')">
				<v-icon>mdi-close</v-icon>
			</v-btn>
		</v-toolbar> -->
    <s-toolbar
      :label="TitleToolbar"
      dark
      :color="'#BAB6B5'"
      close
      @close="$emit('close')"
      class="mb-6"
    ></s-toolbar>

    <v-alert
				v-model="alert"
				border="right"
				:color="'#9EB8F6'"
				dark
				dense
				shaped
				close-icon="fas fa-times-circle"
				style="border-radius: 10px 10px 10px 10px; z-index: 5; width: 100%; right: 0px; top: 5%; margin-top: -22px;}"
			>
				<div>
					<v-row justify="center" style="margin-top: -10px;">
						<v-col lg="5">
							<b>Productor:</b> {{ items.PrdCardName }}
						</v-col>
						<v-col lg="4">
							<b>Lote: </b>{{ items.FltName }}
						</v-col>
					</v-row>

					<v-row
						justify="center"
						style="margin-top: -10px; font-size: smaller"
					>
						<v-col lg="3">
							<b>Tipo Cultivo:</b> {{ items.TypeCropName }}
						</v-col>
						<v-col lg="3">
							<b>Cultivo:</b> {{ items.TypeCultiveName }}
						</v-col>
						<v-col lg="3">
							<b>Variedad: </b>{{ items.VrtName }}
						</v-col>
					</v-row>
                    
				</div>
			</v-alert>

    <!-- <v-container> -->
    <v-col style="padding-top: 10px">
      <v-card class="pt-0 elevation-0 pb-0" elevation="0">
        <!-- datos de la cabecera -->
        <v-row>
          <v-col>       
          <v-alert
            v-show="itemHead.FfsIsValid != 1"
            border="right"
            :color="'#f42658'"
            dark
            dense
            shaped
            close-icon="fas fa-times-circle"
            style="border-radius: 10px 10px 10px 10px; z-index: 5; width: 100%; right: 0px; top: 5%; margin-top: -22px;}"
          >
            Resultado: LOTE NO APTO <br />
            El Lote NO cumple con los parametros establecidos por calidad        
          </v-alert>

          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" lg="2" md="2">
            <s-date
               
              v-model="itemHead.FfsDateSampling"
              label="Fecha de muestreo"
            ></s-date>
          </v-col>
          <v-col cols="12" lg="2" md="2">
            <s-load v-model="photoSampling" label="Imagen"></s-load>
          </v-col>

          <!-- componente de prueba david domador -->
          <!-- <v-col>
              <s-camera-capture label="camara"></s-camera-capture>
          </v-col> -->

          <v-col cols="12" lg="2" md="2"
            ><s-select-definition
              label="Unidad de Muestreo"
              v-model="itemHead.FsfUnitMeasurement"
              :def="1286"
            ></s-select-definition
          ></v-col>
          <v-col v-if="cultiveType == 3" cols="6" lg="3" md="3">
            <s-text
              decimal
              label="Peso Grs (< 100-119)"
              v-model="itemHead.FfsFatWeightMax"
              @input="validateTotalPercentageMax(itemHead)"
            ></s-text>
          </v-col>
          <v-col v-if="cultiveType == 3" cols="6" lg="3" md="3">
            <s-text
              decimal
              label="Peso Grs (>200)"
              v-model="itemHead.FfsFatWeightMin"
              @input="validateTotalPercentageMin(itemHead)"
            ></s-text>
          </v-col>
          <v-col cols="6" lg="3" md="3">
            <s-text
              v-model="itemHead.FfsNumberJabas"
              ref="FocuesNumberJabas"
              decimal
              label="Número de jabas"
            ></s-text>
          </v-col>
					 <v-col cols="6" lg="3" md="3">
            <s-text
              v-model="itemHead.FfSsampledUnits"
              ref="FocuessampledUnits"
              decimal
              label="Unidades Muestreadas"
            ></s-text>
          </v-col>
          <v-col cols="6" lg="3" md="3">
							<s-text
								v-model="itemHead.FfsSamplingWeight"
								ref="FocuesFfsSamplingWeight"
								decimal
								label="Peso muestra (KG)"
							></s-text>
						</v-col>
          <v-col cols="6" lg="3" md="3">
            <s-select
              :items="itemsDataVariety"
              item-text="VrtName"
              item-value="VrtID"
              return-object
              v-model="objVarietyBRIX"
              label="Variedad"
              ref="colorSelect"
              @input="inputCaliber($event)"
            ></s-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" lg="12" md="12" sm="12" class="pt-0">
            <s-textarea
              v-model="itemHead.FfsObservations"
              label="Observaciones"
              outlined
              full-width
              rows="1 "
              row-height="15"
            ></s-textarea>
          </v-col>
        </v-row>
        <v-row>
						<v-col cols="12" lg="12" md="12" sm="12" class="pt-0">
							<s-select-definition 
								:def="1386" 
								label="Higiene del Camion" 
								v-model="itemHead.TypeCleaningTruck" />
						</v-col>
					</v-row>
        <!-- fin de datos de la cabecera -->
        <v-row>
          <v-col class="pt-0 pb-3"><v-divider></v-divider></v-col>
        </v-row>

        <v-row>
          <v-col cols="12" lg="6" class="pt-0">
            <v-row class="pt-3 pr-3 pl-3">
              <h3>Muestreo | Madurez</h3>
              <v-spacer></v-spacer>
              <!-- <v-btn rounded color="" fab x-small @click="addSize()"
                ><i class="fas fa-plus"></i
              ></v-btn> -->
             
            </v-row>
            <v-row>
              <v-col cols="12">
                    
                   <!--samir-->
              <v-row v-if="this.items.TypeCultive == 4"  >
                  <v-col cols="6">
                  <v-col>
                    <v-row>
                    <h3 class="mt-4">Acidez</h3>
                    <v-spacer></v-spacer>
                    <v-btn rounded fab x-small @click="addAcidity()"
                      ><i class="fas fa-plus"></i
                    ></v-btn>
                    </v-row>
                  </v-col>
                  <v-divider></v-divider>
                  <v-col>
                    <v-row>
                      <v-col cols="6" lg="6" md="6" sm="12" class="pl-0 pb-0">
                        <s-text
                          label="% de Acidez" 
                          decimal
                          v-model="FafAcidityValue"
                          ref="focusFafValue"
                        ></s-text>
                      </v-col>
  
                    </v-row>
                    <v-row>
                      <v-col class="pl-0 pt-0">
                        <v-data-table
                          dense
                          :headers="headersAcidity"
                          :items="itemsDataAcidity"
                          hide-default-footer
                          :items-per-page="-1"
                          disable-sort
                          item-key="Line"
                        >
                          <template v-slot:item.Actions="{ item }">
                            <v-btn
                              @click="clickDeleteAcidity(item)"
                              x-small
                              block
                              rounded
                              color="error"
                            >
                              <v-icon style="font-size: 16px !important"
                                >mdi-close</v-icon
                              >
                            </v-btn>
                          </template>
                        </v-data-table>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-col>
                <v-col cols="6">
                  <v-col>
                    <v-row>
                    <h3 class="mt-4">Brix</h3>
                    <v-spacer></v-spacer>
                    <v-btn rounded fab x-small @click="addBrix()"
                      ><i class="fas fa-plus"></i
                    ></v-btn>
                    </v-row>
                  </v-col>
                  <v-divider></v-divider>
                  <v-col >
                    <v-row>
                      <v-col cols="12" class="pl-0 pb-0">
                        <s-text
                          v-model="FfsBrixValue"
                          label="valor Brix" 
                          decimal
                          ref="focusBrixValue"
                        ></s-text>
                      </v-col>

                      
                    </v-row>
                    <v-row>
                      <v-col class="pl-0 pt-0">
                        <v-data-table
                          dense
                          :headers="headersBrix"
                          :items="itemsDataBrix"
                          hide-default-footer
                          :items-per-page="-1"
                          disable-sort
                          item-key="Line"
                        >
                          <template v-slot:item.Actions="{ item }">
                            <v-btn
                              @click="clickDeleteBrix(item)"
                              x-small
                              block
                              rounded
                              color="error"
                            >
                              <v-icon style="font-size: 16px !important"
                                >mdi-close</v-icon
                              >
                            </v-btn>
                          </template>
                        </v-data-table>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-col>
              </v-row>                      
              <v-row  >
                  <v-col cols="6" v-if="this.items.TypeCultive != 4">
                      <v-col>
                    <v-row>
                    <h3 class="mt-4">Materia Seca</h3>
                    <v-spacer></v-spacer>
                    <v-btn rounded fab x-small @click="addSize()"
                      ><i class="fas fa-plus"></i
                    ></v-btn>
                    </v-row>
                  </v-col>
                  <v-divider></v-divider>
                  <v-col>
                    <v-row>
                      <v-col cols="6" lg="6" md="6" sm="12" class="pl-0 pb-0">
                        <s-select-definition
                          label="Tamaño de muestra"
                          return-object
                          v-model="TypeSize"
                          @input="getTypeSize($event)"
                          :def="1370"
                        >
                        </s-select-definition>
                      </v-col>

                      <v-col cols="6" class="pl-0 pb-0">
                        <s-text
                          v-model="SzfValue"
                          ref="focusSzfValue"
                          label="Valor Muestra"
                          decimal
                          @keyupEnter="addSize()"
                        ></s-text>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="pl-0 pt-0">
                        <v-data-table
                          dense
                          :headers="headersSize"
                          :items="itemsDataSize"
                          hide-default-footer
                          :items-per-page="-1"
                          disable-sort
                          item-key="Line"
                        >
                          <template v-slot:item.Actions="{ item }">
                            <v-btn
                              @click="clickDeleteSize(item)"
                              x-small
                              block
                              rounded
                              color="error"
                            >
                              <v-icon style="font-size: 16px !important"
                                >mdi-close</v-icon
                              >
                            </v-btn>
                          </template>
                        </v-data-table>
                      </v-col>
                    </v-row>
                  </v-col>
              
                  </v-col>
                  <v-col cols="6">                       
                    <v-col>
                      <v-row>
                        <h3 class="mt-4">Madurez</h3>
                        <v-spacer></v-spacer>
                        <v-btn rounded fab x-small @click="addMaturity()"
                          ><i class="fas fa-plus"></i
                        ></v-btn>
                      </v-row>
                    </v-col>
                      <v-divider></v-divider>
                    <v-container>
                      <v-row>
                        <v-col cols="6" class="pb-0 pl-0">
                          <s-select-definition
                            label="Tipo madurez"
                            return-object
                            :def="1367"
                            @input="getTypeMaturity($event)"
                          >
                          </s-select-definition>
                        </v-col>

                        <v-col cols="6" class="pl-0 pb-0">
                          <s-text
                            label="valor madurez"
                            v-model="itemsMaturity.FmfValue"
                            decimal
                          >
                          </s-text>
                          <!-- <s-select
                              :items="itemColorMadurity"
                              item-text="BxcColorDescription"
                              item-value="BxcColorHexadecimal"
                              return-object
                              v-model="ColorTextDescription"
                              label="Color"
                              ref="colorSelect"
                              @input="inputColor($event)"
                            ></s-select> -->
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="pt-0 pl-0">
                          <v-data-table
                            dense
                            :headers="headersMaturity"
                            hide-default-footer
                            :items="itemsDataMaturity"
                            :items-per-page="-1"
                            disable-sort
                          >
                            <template v-slot:item.TypeMaturityName="{ item }">
                              {{ item.TypeMaturityName }}
                              <v-chip
                                x-small
                                :color="'#' + item.BxcColorHexadecimal"
                              ></v-chip>
                            </template>

                            <template v-slot:item.Actions="{ item }">
                              <v-btn
                                @click="clickDeleteMaturity(item)"
                                x-small
                                color="error"
                                dark
                                block
                                rounded
                              >
                                <v-icon style="font-size: 16px !important">
                                  mdi-close</v-icon
                                >
                              </v-btn>
                            </template>
                          </v-data-table>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-col>              
              </v-row>
              </v-col>   
            </v-row>
            
          </v-col>

          <v-divider vertical></v-divider>
          <v-col cols="12" lg="6" class="pt-0">
            <h3>Defectos</h3>
            <v-col style="padding-top: 30px">
              <v-row style="padding-bottom: 16px">
                <!-- <v-col
										cols="12"
										lg="4"
										md="4"
										sm="12"
										class="pl-0 pb-0"
									>
										<s-select
											:items="itemsDataVariety"
											item-text="VrtName"
											item-value="VrtID"
											return-object
											v-model="objVarietyDamage"
											label="Variedad"
											ref="colorSelect"
											@input="inputVariety3($event)"
										></s-select>
									</v-col> -->

                <v-col cols="12" lg="6" md="6" class="pl-0 pb-0">
                  <s-text
                    v-model="itemDamage.FdfPercentage"
                    type="decimal"
                    label="Porcentaje Daño"
                    ref="quantityDamage"
                  ></s-text>
                </v-col>
                <v-col cols="12" lg="6" md="6" class="pl-0 pb-0">
                  <s-select
                    label="Tipo de defecto"
                    :items="DamageCultive"
                    v-model="itemDamage.ObjTypeDefects"
                    item-text="DfcName"
                    item-value="DfcID"
                    return-object
                    @input="inputDamage($event)"
                  >
                  </s-select>
                </v-col>
              </v-row>
              <v-row>
                <h3>Muestras</h3> 
                <v-spacer></v-spacer>
                <v-btn rounded fab x-small @click="addDamage()"
                  ><i class="fas fa-plus"></i
                ></v-btn>
              </v-row>
              <v-divider></v-divider>
              <!-- <v-container style="padding-top: 20px"> -->
              <v-row class="mt-5">
                <v-col class="pl-0 pt-0">
                  <v-data-table
                    style="padding-bottom: 16px"
                    dense
                    :headers="headerDamage"
                    :items="itemsDataDamage"
                    hide-default-footer
                    :items-per-page="-1"
                    disable-sort
                    item-key="Line"
                  >
                    <template v-slot:item.Actions="{ item }">
                      <v-btn
                        @click="clickDeleteDamage(item)"
                        x-small
                        block
                        rounded
                        color="error"
                      >
                        <v-icon style="font-size: 16px !important"
                          >mdi-close</v-icon
                        >
                      </v-btn>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
              <!-- </v-container> -->

              <!-- botones -->
              <v-row style="padding-top: 40px">
                <v-spacer></v-spacer>
                <v-col cols="12" lg="6" md="6">
                  <v-btn block rounded color="warning">Cancelar</v-btn>
                </v-col>
                <v-col cols="12" lg="6" md="6">
                  <v-btn
                    class="ml-3"
                    block
                    rounded
                    color="primary"
                    @click="save()"
                    >Guardar</v-btn
                  >
                </v-col>
              </v-row>
            </v-col>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
    <!-- </v-container> -->
  </div>
</template>
<script>

// componente de prueba
import SCameraCapture from "@/components/Utils/SCameraCapture";

import _sDamageFlawCultiveService from "@/services/FrozenProduction/DamageFlawCultiveService";

import _sBrixFreshService from "@/services/FreshProduction/BrixFreshService";
import _sReceptionFresh from "@/services/FreshProduction/ReceptionFreshService";
import _sDamage from "@/services/FreshProduction/SampleDamage/SampleQuality";
import SampleDamage from "@/views/FreshProduction/RawMaterialReception/SampleDamage/SampleDamage";
import SSelectVariety from "@/components/HarvestSchedule/SSelectVariety";
import _sParameter from "@/services/General/ParameterService.js";
import _sHelper from "@/services/HelperService";

import _sFrozenSampling from "@/services/FrozenProduction/FrzfrozenSampling.js";
import _sReceptionStorageService from "../../../services/Technicalassistance/ReceptionStorageService";
import _sTechnicalSpecificationService from "@/services/FrozenProduction/FrzTechnicalSpecificationService.js"

// SERVICIO CREADO POR DAVID 
import _sFrzTechnicalspecificationsByCultive from "@/services/FrozenProduction/FrzTechnicalSpecificationsByCultiveService.js";


export default {
  components: { SampleDamage, SSelectVariety, SCameraCapture },
  props: {
    items: null,
    TypeShip: null,
  },

  data: () => ({
    alert: true,
    TitleToolbar: "Muestreo  de ",
    MinAcidity:0.8,
    MaxAcidity:1.2,
    DamageCultive: null,
    objVarietyBRIX: null,
    objVarietyColor: null,
    objVarietyDamage: null,
    itemHead: {},

    SzfValue: null,
    TypeSize: null,
    itemsDataSize: [],
    itemsDeleteSize: [],

    photoSampling: [],
    itemsMaturity: {},
    itemsDataVariety: [],
    itemDamage: {},
    itemDamageData: [],
    FfsID: 0,
    dialogCapataz: false,
    objForeman: null,
    objForemanSend: null,
    maxBRIX: 0,
    minBRIX: 0,
    quantityBRIX: 0,
    percentageSample: 0,
    loadDamage: false,
    itemsGang: [],
    itemSampleDamage: [],
    isEdit: false,
    itemsDataColor: [],
    itemsDataMaturity: [],
    itemsDataDamage: [],
    itemsDataBrix: [],
    itemSample: {},
    itemsDeleteBrix: [],
    TypeMaturityDef: 0,
    DedDescriptionMaturity: "",

    cultiveType : 0,

    FafAcidityValue:0,
    itemsDataAcidity: [],
    itemsDeleteAcidity: [],
    itemsDeleteMaturity: [],
    itemsDeleteColor: [],
    itemsDeleteDamage: [],
    TypeVariety1: 0,
    DedDescription1: "",
    TypeVariety2: 0,
    DedDescription2: "",
    TypeVariety3: 0,
    DedDescription3: "",
    FfsBrixValue: "",
    Actions: "",
    itemsSpecificationsCultive: [],
    itemColorVariety: [],
    HexadecimalColor: "",
    ColorDescription: "",
    ColorID: 0,
    ColorTextDescription: null,
    itemsData: {},
    BrxID: 0,
    headersBrix: [
      { text: "Variedad", value: "VrtDescription" },
      { text: "Brix", value: "FfsBrixValue", width: 70 },
      { text: "", value: "Actions", width: 80 },
    ],

    headersSize: [
      { text: "Variedad", value: "VrtDescription" },
      { text: "Tamaño", value: "TypeSizeName" },
      { text: "Muestra", value: "SzfValue", width: 70 },
      { text: "", value: "Actions", width: 80 },
    ],
    headersMaturity: [
      { text: "Variedad", value: "VrtDescription", width: 70 },
      { text: "Tipo madurez", value: "TypeMaturityName", width: 100 },
      { text: "Madurez", value: "FmfValue", width: 80 },
      { text: "", value: "Actions", width: 80 },
    ],
    headerDamage: [
      { text: "Variedad", value: "VrtDescription" },
      { text: "Porcentaje", value: "FdfPercentage", width: 100 },
      // { text: "Cantidad Fruta", value: "FdfAmountFruit", width: 120 },
      { text: "Tipo de defecto", value: "DfcName", width: 130 },
      { text: "", value: "Actions", width: 80 },
    ],
    headersAcidity: [
      { text: "Variedad", value: "VrtDescription" },
      { text: "Acidez", value: "FafAcidityValue", width: 70 },
      { text: "", value: "Actions", width: 80 },
    ],
    TechnicalSpe: {},
    sumTotalMature: 0
  }),

  methods: {
    // VALIDACIÓN GENERAL
    // isValidated() {
    //   var validate = false;
    //   let countBrix = this.itemsDataBrix.length;
    //   let countColor = this.itemsDataColor.length;

    //   if (this.objForeman == null) {
    //     this.$fun.alert("No existe Capataz Asociado", "warning");
    //     return false;
    //   }
    //   if (
    //     this.itemSample.BrxNumberJabas <= 0 ||
    //     this.itemSample.BrxNumberJabas == null
    //   ) {
    //     this.$refs.FocuesNumberJabas.error(
    //       "Debe insertar la cantidad de cajas"
    //     );
    //   } else if (countBrix <= 0) {
    //     this.$fun.alert("Debe registrar Datos de brix", "warning");
    //   } else if (countColor <= 0) {
    //     this.$fun.alert("Debe registrar Datos de Color", "warning");
    //   } else {
    //     validate = true;
    //   }
    //   return validate;
    // },
    validateTotalPercentageMax(item)
    {
      var FfsFatWeightMaxOld = item.FfsFatWeightMax;
      let total = parseInt(item.FfsFatWeightMax) + parseInt(item.FfsFatWeightMin);
      if( total > 100 )
      {
        this.$fun.alert("Ya alcanzo el 100%");
        item.FfsFatWeightMax = 0;
        return;
      }
    },

    validateTotalPercentageMin(item)
    {
      var FfsFatWeightMinOld = item.FfsFatWeightMin;
      let total = parseInt(item.FfsFatWeightMax) + parseInt(item.FfsFatWeightMin);
      if( total > 100 )
      {
        this.$fun.alert("Ya alcanzo el 100%");
        item.FfsFatWeightMin = 0;
        return;
      }
    },

    inputColor(item) {
      this.HexadecimalColor = item ? item.BxcColorHexadecimal : "";
      this.ColorDescription = item ? item.BxcColorDescription : "";
      this.ColorID = item ? item.BxcID : "";
    },

    inputCaliber(item) {
    //   console.log("valor de la variedad", item);
      this.TypeVariety1 = item ? item.VrtID : "";
      this.DedDescription1 = item ? item.VrtName : "";

      this.inputCaliberTwo(item);
    },

    inputDamage(item) {
      this.TypeDefects = item ? item.DfcID : "";
      this.TypeDefectsName = item ? item.DfcName : "";
    },

    inputCaliberTwo(item) {
      this.TypeVariety2 = item ? item.VrtID : "";
      this.DedDescription2 = item ? item.VrtName : "";
      // Llamamos al color dependiendo la variedad
      this.getColorVariety(this.TypeVariety2);
    },

    inputVariety3(item) {
      this.TypeVariety3 = item ? item.VrtID : "";
      this.DedDescription3 = item ? item.VrtName : "";
    },

    getTypeMaturity(item) {
      this.TypeMaturityDef = item ? item.DedValue : "";
      this.DedDescriptionMaturity = item ? item.DedDescription : "";
    },

    getTypeSize(item) {
      this.TypeSizeDef = item ? item.DedValue : "";
      this.DedDescriptionSize = item ? item.DedDescription : "";
    },
    clickDeleteAcidity(item) {
      
     
      if (this.itemsDataAcidity.length > 0) {
        if (item.FafID > 0) {
          this.itemsDeleteAcidity.push(
            this.itemsDataAcidity.filter((x) => x.Line == item.Line)[0]
          );
          this.itemsDeleteAcidity.forEach((element) => {
            element.SecStatus = 0;
          });
        }

        this.itemsDataAcidity = this.itemsDataAcidity.filter(
          (x) => x.Line != item.Line
        );
        this.orderDetailsAcidity();
      }
    },
    clickDeleteBrix(item) {
     /* console.log("datos", item);
			 c	this.itemsDataBrix = this.itemsDataBrix.filter(
					(x) => x.FfsBrixValue != item.FfsBrixValue
				);
				item.SecStatus = 0;
				if (this.isEdit) {
					this.itemsDeleteBrix.push(item);
				} */
     
      if (this.itemsDataBrix.length > 0) {
        if (item.FbfID > 0) {
          this.itemsDeleteBrix.push(
            this.itemsDataBrix.filter((x) => x.Line == item.Line)[0]
          );
          this.itemsDeleteBrix.forEach((element) => {
            element.SecStatus = 0;
          });
        }

        this.itemsDataBrix = this.itemsDataBrix.filter(
          (x) => x.Line != item.Line
        );
        this.orderDetailsBrix();
      }
    },

    clickDeleteDamage(item) {
      /* console.log("Datos daño", item);
				this.itemsDataDamage = this.itemsDataDamage.filter(
					(x) => x.TypeDefects != item.TypeDefects
				);
				item.SecStatus = 0;
				if (this.isEdit) this.itemsDeleteDamage.push(item); */

      if (this.itemsDataDamage.length > 0) {
        if (item.FdfID > 0) {
          this.itemsDeleteDamage.push(
            this.itemsDataDamage.filter((x) => x.Line == item.Line)[0]
          );
          this.itemsDeleteDamage.forEach((element) => {
            element.SecStatus = 0;
          });
        }

        this.itemsDataDamage = this.itemsDataDamage.filter(
          (x) => x.Line != item.Line
        );
        this.orderDetailsDamage();
      }
    },

    clickDeleteMaturity(item) {
      if (this.itemsDataMaturity.length > 0) {
        if (item.FmfID > 0) {
    //   console.log("samir",item);
    //   console.log("itemsDeleteMaturity samir",this.itemsDeleteMaturity);

          this.itemsDeleteMaturity.push(
            this.itemsDataMaturity.filter((x) => x.Line == item.Line)[0]
          );
          this.itemsDeleteMaturity.forEach((element) => {
            element.SecStatus = 0;
          });
        }

        this.itemsDataMaturity = this.itemsDataMaturity.filter(
          (x) => x.Line != item.Line
        );
        this.orderDetailsMaturity();
      }
    },

    clickDeleteSize(item) {
      if (this.itemsDataSize.length > 0) {
        if (item.SzfID > 0) {
          this.itemsDeleteSize.push(
            this.itemsDataSize.filter((x) => x.Line == item.Line)[0]
          );
          this.itemsDeleteSize.forEach((element) => {
            element.SecStatus = 0;
          });
        }

        this.itemsDataSize = this.itemsDataSize.filter(
          (x) => x.Line != item.Line
        );
        this.orderDetailsSize();
      }
    },

    clickDeleteColor(item) {
      if (this.itemsDataColor.length > 0) {
        if (item.FcfID > 0) {
          this.itemsDeleteColor.push(
            this.itemsDataColor.filter((x) => x.Line == item.Line)[0]
          );
          this.itemsDeleteColor.forEach((element) => {
            element.SecStatus = 0;
          });
        }

        this.itemsDataColor = this.itemsDataColor.filter(
          (x) => x.Line != item.Line
        );
        this.orderDetailsColor();
      }
    },

    orderDetailsSize() {
      let i = 0;
      this.itemsDataSize.map((e) => {
        i++;
        e.Line = i;
      });
    },
    orderDetailsAcidity() {
      let i = 0;
      this.itemsDataAcidity.map((e) => {
        i++;
        e.Line = i;
      });
    },
    orderDetailsBrix() {
      let i = 0;
      this.itemsDataBrix.map((e) => {
        i++;
        e.Line = i;
      });
    },

    orderDetailsMaturity() {
      let i = 0;
      this.itemsDataMaturity.map((e) => {
        i++;
        e.Line = i;
      });
    },

    orderDetailsColor() {
      let i = 0;
      this.itemsDataColor.map((e) => {
        i++;
        e.Line = i;
      });
    },

    orderDetailsDamage() {
      let i = 0;
      this.itemsDataDamage.map((e) => {
        i++;
        e.Line = i;
      });
    },

    addBrix() {
      if (this.itemHead.FfsNumberJabas <= 0) {
        this.$fun.alert(
          "Error, ingrese la cantidad de jabas  a muestrear",
          "warning"
        );
        return;
      }

      if (this.itemsDataBrix.length + 1 > this.itemHead.FfsNumberJabas) {
        this.$fun.alert(
          "Error, No puede superar la cantidad de unidades a muestrear",
          "warning"
        );
        return;
      }

      if (this.FfsBrixValue.trim() == "") {
        this.$refs.focusBrixValue.error("Debe ingresar un Brix valido");
        return;
      }
      
      if (this.FfsBrixValue <= 0) {
        this.$refs.focusBrixValue.error("Debe ingresar un Brix valido");
      } else {
        if (
          this.FfsBrixValue > 0.11
        ) {
          let itemsBrix = {};
          itemsBrix.FfsID = this.FfsID ? this.FfsID : 0;
          itemsBrix.VrtID = this.TypeVariety1;
          itemsBrix.FfsBrixValue = this.FfsBrixValue;
          itemsBrix.VrtDescription = this.DedDescription1;
          itemsBrix.UsrCreateID = this.$fun.getUserID();
          itemsBrix.UsrUpdateID = this.$fun.getUserID();
          itemsBrix.Line = this.itemsDataBrix.length + 1;

          this.itemsDataBrix.push(itemsBrix);

          this.orderDetailsBrix();

        //   console.log("datos del brix", this.itemsDataBrix);

          this.FfsBrixValue = "";
          this.$refs.focusBrixValue.focus();
        } else {
          this.$fun.alert(
            "El valor de BRIX fuera del rango "
          );
        }
      }
    },

    addSize() {
      if (this.itemHead.FfsNumberJabas <= 0) {
        this.$fun.alert(
          "Error, ingrese la cantidad jabas a muestrear",
          "warning"
        );
        return;
      }

			if(this.itemHead.FfSsampledUnits <= 0){
				this.$fun.alert("Error, ingrese la cantidad de unidades a muestrear", "warning");
				return;
			}

      if (this.SzfValue == "" || this.SzfValue == null) {
        this.$refs.focusSzfValue.error("Error, Ingrese el valor de muestra ");
        return;
      }

      if (this.SzfValue.trim() == "") {
        this.$refs.focusSzfValue.error("Debe ingresar el valor de muestra ");
        return;
      }

      if (this.SzfValue <= 0) {
        this.$refs.focusSzfValue.error("Debe ingresar el valor de muestra");
      } else {
        // this.addMaturity();

        let itemsSize = {};
        itemsSize.FfsID = this.FfsID ? this.FfsID : 0;
        itemsSize.VrtID = this.TypeVariety1;
        itemsSize.TypeSize = this.TypeSizeDef;
        itemsSize.TypeSizeName = this.DedDescriptionSize;
        itemsSize.SzfValue = this.SzfValue;
        itemsSize.VrtDescription = this.DedDescription1;
        itemsSize.UsrCreateID = this.$fun.getUserID();
        itemsSize.UsrUpdateID = this.$fun.getUserID();
        itemsSize.Line = this.itemsDataSize.length + 1;

        this.itemsDataSize.push(itemsSize);

        this.orderDetailsSize();
				this.SzfValue = "";

        // this.SzfValue="";
      }
    },
    addAcidity() {
      if (this.itemHead.FfsNumberJabas <= 0) {
        this.$fun.alert(
          "Error, ingrese la cantidad jabas a muestrear",
          "warning"
        );
        return;
      }

			if(this.itemHead.FfSsampledUnits <= 0){
				this.$fun.alert("Error, ingrese la cantidad de unidades a muestrear", "warning");
				return;
			}
 
      if (this.FafAcidityValue == "" || this.FafAcidityValue == null) {
        this.$refs.focusFafValue.error("Error, Ingrese el valor de muestra sa");
        return;
      }

      if (this.FafAcidityValue.trim() == "") {
        this.$refs.focusFafValue.error("Debe ingresar el valor de muestra ");
        return;
      }

      if (this.FafAcidityValue <= this.MinAcidity || this.FafAcidityValue >= this.MaxAcidity) {
        this.$refs.focusFafValue.error(
          "Debe ingresar un valor dentro del intervalo de " + this.MinAcidity + " - " + this.MaxAcidity
          );
      } else {
        // this.addMaturity();
        let itemsAcidity = {};
      
        itemsAcidity.FfsID = this.FfsID ? this.FfsID : 0;
        itemsAcidity.VrtID = this.TypeVariety1;
        itemsAcidity.VrtDescription = this.DedDescription1;
        itemsAcidity.FafAcidityValue = this.FafAcidityValue;
        itemsAcidity.UsrCreateID = this.$fun.getUserID();
        //itemsAcidity.UsrUpdateID = this.$fun.getUserID();
        itemsAcidity.Line = this.itemsDataAcidity.length + 1;


        this.itemsDataAcidity.push(itemsAcidity);

        this.orderDetailsAcidity();
				this.FafAcidityValue = 0;

        // this.SzfValue="" samir;
      }
    },


    addDamage() {
      let itemsDamage = {};
      if (
        this.itemDamage.FdfPercentage <= 0 ||
        this.itemDamage.FdfPercentage == null
      ) {
        this.$refs.quantityDamage.error("Error, Ingrese el porcentaje de daño");
        //this.$fun.alert("Error, cantidad de fruta", "warning");
        return;
      }

      if (this.itemDamage.ObjTypeDefects == null) {
        this.$fun.alert("Seleccione Tipo de defecto", "warning");
        return;
      }
      itemsDamage.FfsID = this.FfsID ? this.FfsID : 0;
      itemsDamage.VrtID = this.TypeVariety1;
      itemsDamage.VrtDescription = this.DedDescription1;
      itemsDamage.DfcID = this.TypeDefects;
      itemsDamage.DfcName = this.TypeDefectsName;
      itemsDamage.FdfPercentage = this.itemDamage.FdfPercentage;
      itemsDamage.FdfAmountFruit = this.itemDamage.FdfAmountFruit;
      itemsDamage.UsrCreateID = this.$fun.getUserID();
      itemsDamage.UsrUpdateID = this.$fun.getUserID();
      this.itemsDataDamage.push(itemsDamage);

      this.orderDetailsDamage();
      this.itemDamage = {};

      this.$refs.quantityDamage.focus();
    },

    addAll() {
      this.addBrix();
      this.addColor();
    },

    addMaturity() {
      if (this.itemHead.FfsNumberJabas <= 0) {
        this.$fun.alert(
          "Error, ingrese la cantidad de jabas  a muestrear",
          "warning"
        );
        return;
      }

			if(this.itemHead.FfSsampledUnits <= 0){
				this.$fun.alert("Error, ingrese la cantidad de unidades a muestrear", "warning");
				return;
			}
      if (
        this.itemsMaturity.FmfValue == "" ||
        this.itemsMaturity.FmfValue == null
      ) {
        this.$fun.alert("Error, Debe ingresar un valor de madurez", "warning");
        return;

      } else {
        let objMaturtity = {};
        this.sumTotalMature = 0;
				
        //VALIDAR QUE NO PASE EL 100%
        this.itemsDataMaturity.forEach(element => {
          this.sumTotalMature += parseFloat(element.FmfValue);
        })
        this.sumTotalMature += parseFloat(this.itemsMaturity.FmfValue);
        
        if(this.sumTotalMature > 100)
        {
          this.$fun.alert("Ya alcanzo el 100%");
          return;
        }
        objMaturtity.FfsID = this.FfsID ? this.FfsID : 0;
        objMaturtity.VrtID = this.TypeVariety1;
        objMaturtity.Line = this.itemsDataMaturity.length + 1;
        objMaturtity.TypeMaturity = this.TypeMaturityDef;
        objMaturtity.VrtDescription = this.DedDescription1;
        objMaturtity.TypeMaturityName = this.DedDescriptionMaturity;
        objMaturtity.FmfValue = this.itemsMaturity.FmfValue;
        objMaturtity.UsrCreateID = this.$fun.getUserID();

        this.itemsDataMaturity.push(objMaturtity);
        
        // debugger;

        this.orderDetailsMaturity();
				this.itemsMaturity.FmfValue = "";
      }
    },

    addColor() {
      if (this.itemHead.FfsNumberJabas <= 0) {
        this.$fun.alert(
          "Error, ingrese la cantidad de unidades  a muestrear",
          "warning"
        );
        return;
      }
      if (this.itemsDataColor.length + 1 > this.itemHead.FfsNumberJabas) {
        this.$fun.alert(
          "Error, No puede superar la cantidad de unidades a muestrear",
          "warning"
        );
        return;
      }
      if (this.ColorTextDescription == null) {
        this.$fun.alert("Debe seleccionar un color", "warning");
      } else {
        let itemsColor = {};
        itemsColor.FfsID = this.FfsID ? this.FfsID : 0;
        itemsColor.VrtID = this.TypeVariety2;
        itemsColor.VrtDescription = this.DedDescription2;
        itemsColor.Line = this.itemsDataColor.length + 1;
        itemsColor.BxcColorDescription = this.ColorDescription;
        itemsColor.BxcID = this.ColorID;
        itemsColor.UsrCreateID = this.$fun.getUserID();
        itemsColor.BxcColorHexadecimal = this.HexadecimalColor;

        this.itemsDataColor.push(itemsColor);

        this.orderDetailsColor();

        // console.log("Datos del color", this.itemsDataColor);

        this.ColorTextDescription =
          this.itemColorVariety.length > 0 ? this.itemColorVariety[0] : null;
        // this.HexadecimalColor = "EEEE";
      }
    },

    uploadFilePhoto() {
      console.log('photoSamplingphotoSampling ', this.photoSampling.length);
      /* this.photoSampling != null ||  */
      if (this.photoSampling.length > undefined) {
        var formData = new FormData();
        formData.append("file", this.photoSampling);
        _sHelper
          .uploadfile(1, this.$fun.getUserID(), formData, 10)
          .then((resp) => {
            if (resp.status == 200) {
              this.photoSampling = [];
              return;
            }
          });
      }
      /*  else {
        this.$fun.alert("Sin archivo para subir");
      } */
    },

    save() {

        // 1 = el lote es valido
        // 2 = el lote NO es valido
        this.itemHead.FfsIsValid = 1; 

        var QuantitSize = 0, countSize = 0, averageSize = 0; 						
        this.itemsDataSize.forEach(element => {
            QuantitSize = (QuantitSize + parseInt(element.SzfValue));
            countSize++;
        }); 
        averageSize = (QuantitSize/countSize);       
        

        let QuantityMaturity = 0, countMaturity =0, averageMaturity = 0;
        this.itemsDataMaturity.forEach(element => {
            QuantityMaturity = (QuantityMaturity + parseInt(element.FmfValue));   
            countMaturity++;
        });
        averageMaturity = (QuantityMaturity / countMaturity)
        
        // recorremos las especificaciones tecnicas para saber sus valores
        this.itemsSpecificationsCultive.forEach(element => {
            if(element.TypEspecifications == 4){
                if(averageMaturity < element.FsaMinValue || averageMaturity < element.FsaMaxValue  ) {
                    this.itemHead.FfsIsValid = 2;
                }
            }
            if(element.TypEspecifications == 8) {
                if(averageSize < element.FsaMinValue || averageSize < element.FsaMaxValue  ) {
                    this.itemHead.FfsIsValid = 2;                            
                }
            }
        })

       
    //validacion de especificaciones tecnicas samirP ara los defectos     
      this.DamageCultive.forEach(element => {    
          this.itemsDataDamage.forEach(element2 => {          
            if (element.DfcName == element2.DfcName) {
              if (element.DfcTolerance < element2.FdfPercentage) {
                //enviamos a la BD que no cumple con los parametros
                this.itemHead.FfsIsValid = 2;
              } 
            }
          });
      });

    //   console.log("Resultado FfsIsValid", this.itemHead.FfsIsValid);
    //   console.log("Resultado FfsIsValid", this.itemHead);
     // return;

      if(this.items.TypeCultive == 3){
          if (this.itemHead.FfsFatWeightMax == 0 || this.itemHead.FfsFatWeightMax == "") {
            this.$fun.alert("Error, Debe ingresar un porcentaje de Grs", "warning");
            return;
          }

          if (this.itemHead.FfsFatWeightMax == 0 || this.itemHead.FfsFatWeightMin == "") {
            this.$fun.alert("Error, Debe ingresar un porcentaje de Grs", "warning");
            return;
          }

          if (this.itemsDataSize.length == 0) {
            this.$fun.alert("Error, No existen detalles para materia seca", "warning");
            return;
          }
      }

     

      if (this.itemsDataMaturity.length == 0) {
        this.$fun.alert(
          "Error, No existen detalles para muestreo Madurez",
          "warning"
        );
        return;
      }

      if (this.itemsDataDamage.length == 0) {
        this.$fun.alert(
          "Error, No existen detalles para muestreo de daños",
          "warning"
        );
        return;
      }

      this.itemHead.RcfID = this.items.RcfID ? this.items.RcfID : 0;
      this.itemHead.PseID = this.items.PseID ? this.items.PseID : 0;
      this.itemHead.MtpID = this.items.MtpID; //? this.items.MtpID : 0;

      this.itemHead.FfsState = 1;
      this.itemHead.SecStatus = 1;
      this.itemHead.UsrUpdateID = this.$fun.getUserID();
      this.itemHead.UsrCreateID = this.$fun.getUserID();

      this.itemHead.FfsID = this.FfsID ? this.FfsID : 0;
      this.itemHead.FfsHour = this.$fun.formatTimeShortView(this.$moment().format("HH:mm"));;
      this.itemHead.FfsImage =
        this.photoSampling != null ? this.photoSampling.name : "";

      this.itemHead.DataDamageFrozen = [
        ...this.itemsDataDamage,
        ...this.itemsDeleteDamage,
      ];

      this.itemHead.DataBrixFrozen = [
        ...this.itemsDataBrix,
        ...this.itemsDeleteBrix,
      ];
      this.itemHead.DataColorFrozen = [
        ...this.itemsDataColor,
        ...this.itemsDeleteColor,
      ];
      this.itemHead.DataMaturityFrozen = [
        ...this.itemsDataMaturity,
        ...this.itemsDeleteMaturity,
      ];
      this.itemHead.DataSizeFrozen = [
        ...this.itemsDataSize,
        ...this.itemsDeleteSize,
      ];
      this.itemHead.DataAcidityFrozen = [
        ...this.itemsDataAcidity,
        ...this.itemsDeleteAcidity,
      ];

      //samir
      this.itemHead.DataFirmnessFrozen = [];
      this.itemHead.DataTasteFrozen = [];
      this.itemHead.DataPhFrozen = [];

      //this.itemHead.DataBrixFrozen.push(...this.itemsDeleteBrix,...this.itemsDeleteColor);
      //this.itemHead.DataColorFrozen.push(...this.itemsDeleteColor);
      this.itemHead.FfsPlace = 1;
    //   console.log("Guardar", this.itemHead);

      this.$fun.alert("Esta seguro de guardar?", "question").then((r) => {
        if (r.value) {
          _sFrozenSampling
            .save(this.itemHead, this.$fun.getUserID())
            .then((r) => {
              if (r.status == 200) {
                /* this.uploadFilePhoto(); */
                this.$fun.alert("Registrado correctamente", "success");
                this.$emit("close");

                // this.itemHead.DataDamageFrozen = [];
                // this.itemHead.DataBrixFrozen = [];
                // this.itemHead.DataColorFrozen = [];
              }
            });
        }
      });
    },

    getSample() {
    //   console.log("entramos a la funcion para editar");
      //  BUSCAMOS SI HAY REGISTRO DE ESE DATO EN NUESTRA TABLA DE MUESTREO
      let obj = {
        TypeShip: 2,
        MtpStatus: 0,
        RcfID: this.items.RcfID,
        PseID: this.items.RcfID,
        FfsID: 0,
        MtpID: 0,
      };

      _sFrozenSampling.sampling(obj, this.$fun.getUserID()).then((r) => {
        // console.log("Datos traidos de muestreo samir", r.data);
        if (r.data != null) {
        //   console.log("SAMIR de la cabecera", r);
          // // ID DE LA CABECERA
          this.FfsID = r.data.FfsID;
          this.itemHead = r.data;
          this.itemsDataBrix = r.data.DataBrixFrozen;
          this.itemsDataColor = r.data.DataColorFrozen;
          this.itemsDataDamage = r.data.DataDamageFrozen;
          this.itemsDataMaturity = r.data.DataMaturityFrozen;
          this.itemsDataSize = r.data.DataSizeFrozen;
          this.itemsDataAcidity = r.data.DataAcidityFrozen;

          this.isEdit = true;
          this.orderDetailsColor();
          this.orderDetailsBrix();
          this.orderDetailsDamage();
          this.orderDetailsMaturity();
          this.orderDetailsSize();
          this.orderDetailsAcidity();
          //samir

        } else {
          this.FfsID = 0;
          this.itemHead = {};
          this.itemsDataBrix = [];
          this.itemsDataColor = [];
          this.itemsDataMaturity = [];
          this.itemsDataDamage = [];
          this.itemsDataSize = [];
          this.itemsDataAcidity = [];
          this.isEdit = false;
          this.itemHead.FfsIsValid = 1;
        }
      });
    },

    getColorVariety(VrtID) {
      // _sReceptionFresh
      //   .GetGang({ RcfID: this.items.RcfID }, this.$fun.getUserID())
      //   .then((r) => {
      //     this.itemsGang = r.data;
      //     if (this.itemsGang.length > 0) this.objForeman = this.itemsGang[0];

      _sBrixFreshService
        .getColorForVariety(VrtID ? VrtID : 0, this.$fun.getUserID())
        .then((r) => {
          this.itemColorVariety = r.data;
          if (this.itemColorVariety.length > 0)
            this.ColorTextDescription = this.itemColorVariety[0];
          //TRAE TODOS LAS MUESTRAS SEGUN CAPATAZ
          //  this.getSample();
        });
      // });
    },

    getSpecification(cultive){
	
        let obj = {
            TypeCultive : cultive,
        };

        _sFrzTechnicalspecificationsByCultive.listByCultive(obj, this.$fun.getUserID())
        .then((r)=>{
            if(r.status == 200) {
                console.log('mostramos las especificaciones', r.data);
                this.itemsSpecificationsCultive = r.data;
            }
        });
    },

  },

  created() {

        this.TitleToolbar += this.items.TypeCultiveName;

        this.getSpecification(this.items.TypeCultive);

  
        if (this.items.TypeCultive != 0) {
        _sDamageFlawCultiveService
            .list(this.items, this.$fun.getUserID())
            .then((r) => {
            if (r.status == 200) {
                // console.log("daños por cultivo", r.data);
                this.DamageCultive = r.data;
                this.cultiveType = this.DamageCultive[0].TypeCultive;
            }
            });
        }

    if (this.items.VrtID == undefined) {
    //   console.log("ID para la busqueda de la variedad", this.items.PseID);
      _sReceptionStorageService
        .GetDetails(
          { PseID: this.items.PseID, PseState: 1 },
          this.$fun.getUserID()
        )
        .then((r) => {
        //   console.log("get details r data samir", r.data);
          this.itemsDataVariety = r.data;
          if (this.itemsDataVariety.length > 0) {
            this.objVarietyBRIX = r.data[0];
            this.objVarietyColor = r.data[0];
            this.objVarietyDamage = r.data[0];
          

          }
        //   console.log(
        //     "datos que traigo con un servicio",
        //     this.itemsDataVariety
        //   );
        });
    } else {
      let itemsVariety = {};
      itemsVariety.VrtID = this.items.VrtID;
      itemsVariety.VrtName = this.items.VrtName;

      this.itemsDataVariety.push(itemsVariety);
      this.objVarietyBRIX = itemsVariety;
      this.objVarietyColor = itemsVariety;
      this.objVarietyDamage = itemsVariety;
    //   console.log("Mostramos las variedades capturadas", this.itemsDataVariety);
    }

    //falta el parametro de min de ACIDITY samir
    /*this.MinAcidity:0;
      this.MaxAcidity:0;
    */
    let a = [];
    a.push(
      "MinimoBRIX",
      "MaximoBRIX",
      "CantidadMuestraBRIX",
      "PorcentajeMuestraLote",
      "MinimoAcidezArandanoFrz",
      "MaximoAcidezArandanoFrz"
    );
    _sParameter.list(a, this.$fun.getUserID()).then((r) => {
    //   console.log("parametros de muestreo de congelado", r.data);
      this.maxBRIX = r.data[0];
      this.minBRIX = r.data[1];
      this.quantityBRIX = r.data[2];
      this.percentageSample = r.data[3];
      this.MinAcidity = r.data[4];
      this.MaxAcidity = r.data[5];
    });

    // llamamos a todos datos de muestre , su cabecera  y su detalle
    this.getSample();
   
  },
};
</script>