<template>
	<div>
		<v-row>
			<v-col cols="6" class="s-col-form">
				<v-card dense outlined :disabled="lotReception.RcfStatusSap == 2">
					<s-toolbar 
						label="1.- Información Recepción"
						color="#E0F7F2"
					></s-toolbar>
					<v-card-text>
						<v-row>
							<v-col class="s-col-form">
								<h4>
									{{"Peso Neto"}}
								</h4>
							</v-col>
							<v-col class="s-col-form">
								<h4>
									{{ lotReception.WbbNetWeight | numberDefaultKg }}
								</h4>
							</v-col>
							<v-col class="s-col-form">
								<v-label>
									{{"-"}}
								</v-label>
							</v-col>
						</v-row>
						<v-row>
							<v-col class="s-col-form">
								<h4>
									{{"Peso Lavado"}}
								</h4>
							</v-col>
							<v-col class="s-col-form">
								<h4>
									{{ lotReception.infSelWeightWash | numberDefaultKg }}
								</h4>
							</v-col>
							<v-col class="s-col-form">
								<v-label>
									{{"-"}}
								</v-label>
							</v-col>
						</v-row>
						 
					</v-card-text>
				</v-card>
			</v-col>
			<v-col cols="6" class="s-col-form">
				<!--  -->
				<v-card dense outlined :disabled="lotReception.RcfStatusSap == 2">
					<s-toolbar 
						label="2.- Tolerancia Descarte Seleccion"
						color="#E0F7F2"
					></s-toolbar>
					<v-card-text>
						<v-row>
							<v-col class="s-col-form">
								<h4>
									{{"Tolerancia aceptada"}}
								</h4>
							</v-col>
							<v-col class="s-col-form">
								<s-text
									v-model="AcceptedToleranceInputDiscart"
									decimal
									readonly 
								/> 
								<!-- :readonly="lotReception.RcfStatusSap == 2" -->
							</v-col>
							<v-col class="s-col-form">
								<h4>
									{{ lotReception.tolWeiDisSelToleranceAcceptedCalculate | numberDefaultKg }}
								</h4>
							</v-col>
						</v-row>
						<v-row>
							<v-col class="s-col-form">
								<h4>
									{{"Descarte no aceptada"}}
								</h4>
							</v-col>
							<v-col class="s-col-form">
								<h4 :style="colorText">
									{{ lotReception.tolWeiDisSelDiferenceNoAcceptedPercentage| numberDefault }} %
								</h4>
							</v-col>
							<v-col class="s-col-form">
								<h4>
									{{ lotReception.tolWeiDisSelDiferenceNoAcceptedCalculate | numberDefaultKg }}
								</h4>
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
		<v-row  class="mb-5">
			<v-col cols="6" class="s-col-form" >
				<v-card dense outlined :disabled="lotReception.RcfStatusSap == 2">
					<s-toolbar 
						label="3.- Descuentos totales"
						color="#E0F7F2"
					></s-toolbar>
					<v-card-text>
						<v-row>
							<v-col class="s-col-form">
								<h4>
									{{"Tolerancia no aceptada + Descarte no aceptada"}}
								</h4>
							</v-col>
							<v-col class="s-col-form">
								<h4>
									{{ lotReception.DisTotDifferenceNoAcceptedJoinDiscartNoAccepted | numberDefaultKg }}
								</h4>
							</v-col>
						</v-row>
						<v-row>
							<v-col class="s-col-form">
								<h4>
									{{"Peso a pagar"}}
								</h4>
							</v-col>
							<v-col class="s-col-form">
								<h4>
									{{ lotReception.DisTotWeightPay | numberDefaultKg }}
								</h4>
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>
			</v-col>
			<v-col cols="6" class="s-col-form">
				<v-card dense outlined :disabled="lotReception.RcfStatusSap == 2">
					<s-toolbar 
						label="4.- Distribución Almacenes"
						color="#E0F7F2"
					></s-toolbar>
					<v-card-text>
						<v-row>
							<v-col class="s-col-form">
								<h4>
									{{"Peso Final aceptable"}}
								</h4>
							</v-col>
							<v-col class="s-col-form">
								<p class="font-weight-black text-end">
									{{ lotReception.RfsWeigth | numberDefaultKg }}
								</p>
							</v-col>
							<v-col class="s-col-form">
								<h4 class="success--text font-weight-black">
									{{"Alm. 02"}}
								</h4>
							</v-col>
						</v-row>
						<v-row>
							<v-col class="s-col-form">
								<h4>
									{{"Merma aceptable"}}
								</h4>
							</v-col>
							<v-col class="s-col-form">
								<p class="font-weight-black text-end">
									{{ lotReception.RfsWeightDiscart | numberDefaultKg }}
								</p>
							</v-col>
							<v-col class="s-col-form">
								<h4  class="red--text font-weight-black">
									{{"Alm. 20"}}
								</h4>
							</v-col>
						</v-row>
						<v-row>
							<v-col class="s-col-form">
								<h4>
									{{ "Peso de bolillo" }}
								</h4>
							</v-col>
							<v-col class="s-col-form">
								<p class="font-weight-black text-end">
									{{ lotReception.RfsWeightBolillo | numberDefaultKg }}
								</p>
							</v-col>
							<v-col class="s-col-form">
								<h4 class="info--text font-weight-black">
									{{ "Bolillo " + lotReception.PorceBolillo + "%" }} 
								</h4>
							</v-col>
						</v-row>
						<v-row>
							<v-col class="s-col-form">
								<h4>
									{{ "Peso de Devolucion" }}
								</h4>
							</v-col>
							<v-col class="s-col-form">
								<p class="font-weight-black text-end">
									{{ lotReception.RfsWeightDevolution | numberDefaultKg }}
								</p>
							</v-col>
							<v-col class="s-col-form">
								<h4 class="info--text font-weight-black">
									{{ "Devolucion" }} 
								</h4>
							</v-col>
						</v-row>
						<v-row>
							<v-col class="s-col-form">
								<h4 class="grey--text font-weight-black">
									{{ "Total SAP" }}
								</h4>
							</v-col>
							<v-col class="s-col-form">
								<p class="grey--text font-weight-black text-end">
									{{ lotReception.TotalSap | numberDefaultKg }}
								</p>
							</v-col>
							<v-col class="s-col-form">
								<h4 class="grey--text font-weight-black">
									{{ "" }} 
								</h4>
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
	</div>
</template>

<script>
	export default {
		props: {
			lotReception: {}
		},

		data() {
			return {
				// lotReception: {},
				AcceptedToleranceInput : 0,
				AcceptedToleranceInputDiscart : 0,
				colorText : '',
			}
		},

		watch: {
			AcceptedToleranceInput()
			{
				this.getCalcule();
			},

			AcceptedToleranceInputDiscart()
			{
				this.getCalcule();
			},
		},

		methods: {
			getCalcule() {
				
			 

				//  this.lotReceptionInput.WbbNetWeight  AcceptedToleranceInput
				if(parseFloat(this.AcceptedToleranceInputDiscart) >= parseFloat(this.lotReception.discartPercentage))
				{ 
					this.lotReception.tolWeiDisSelToleranceAcceptedCalculate = this.lotReception.discart;////.DisFavKgDiscartUpdateCalculate
					this.lotReception.tolWeiDisSelDiferenceNoAcceptedPercentage = 0.00;
					this.lotReception.tolWeiDisSelDiferenceNoAcceptedCalculate = 0.00;
				}
				if(parseFloat(this.AcceptedToleranceInputDiscart) < parseFloat(this.lotReception.discartPercentage))
				{
					//samir
					console.log("2")
					this.lotReception.tolWeiDisSelToleranceAcceptedCalculate = (this.lotReception.infSelWeightWash*(this.AcceptedToleranceInputDiscart/100)).toFixed(4);
					 
					this.lotReception.tolWeiDisSelDiferenceNoAcceptedPercentage = (this.lotReception.discartPercentage -this.AcceptedToleranceInputDiscart).toFixed(4);
					this.lotReception.tolWeiDisSelDiferenceNoAcceptedCalculate = (this.lotReception.discart - this.lotReception.tolWeiDisSelToleranceAcceptedCalculate).toFixed(4);

					this.lotReception.tolWeiDisSelDiferenceNoAcceptedPercentage = this.lotReception.tolWeiDisSelDiferenceNoAcceptedPercentage ?? 0;
					this.lotReception.tolWeiDisSelDiferenceNoAcceptedCalculate = this.lotReception.tolWeiDisSelDiferenceNoAcceptedCalculate ?? 0;
				}

				this.lotReception.DisTotDifferenceNoAcceptedJoinDiscartNoAccepted = (parseFloat(this.lotReception.tolWeiDisSelDiferenceNoAcceptedCalculate)).toFixed(4);
				this.lotReception.DisTotWeightPay = (this.lotReception.RcfWeightGuide - this.lotReception.DisTotDifferenceNoAcceptedJoinDiscartNoAccepted).toFixed(4);

				console.log({ 
					RfsWeightDiscart: this.lotReception.tolWeiDisSelToleranceAcceptedCalculate ,
					tolWeiNetGuiToleranceAcceptedCalculate: this.lotReception.tolWeiNetGuiToleranceAcceptedCalculate 
				})

				// this.lotReception.RfsWeightDiscart = (parseFloat(this.lotReception.tolWeiDisSelToleranceAcceptedCalculate) + parseFloat(this.lotReception.tolWeiNetGuiToleranceAcceptedCalculate)).toFixed(4);
				this.lotReception.RfsWeightDiscart = parseFloat(this.lotReception.tolWeiDisSelToleranceAcceptedCalculate).toFixed(4);
				this.lotReception.RfsWeigth = parseFloat(this.lotReception.infSelWeightWash - this.lotReception.RfsWeightBolillo).toFixed(4);
				
				this.lotReception.TotalSap = parseFloat(this.lotReception.RfsWeigth) + parseFloat(this.lotReception.RfsWeightDiscart) + parseFloat(this.lotReception.RfsWeightBolillo);
				console.log("this.lotReception.TotalSap" , this.lotReception.TotalSap)

				this.lotReception.PorceBolillo = 0;
				if (this.lotReception.RfsWeightBolillo > 0 && this.lotReception.WbbNetWeight) {
					
					this.lotReception.PorceBolillo = ((parseFloat(this.lotReception.RfsWeightBolillo)* 100)/parseFloat(this.lotReception.WbbNetWeight)).toFixed(2)
				}

				//
				
				// this.lotReception.AcceptedToleranceInput = this.AcceptedToleranceInput; 
				this.lotReception.AcceptedToleranceInputDiscart = this.AcceptedToleranceInputDiscart;

				this.lotReception.RfsWeightBolilloc = this.lotReception.RfsWeightBolillo;
			}
		},

		created () {
			// this.lotReception = this.lotReceptionInput;
		},
		mounted(){
			this.lotReception.infSelWeightWash = (this.lotReception.WbbNetWeight - this.lotReception.discart - this.lotReception.RfsWeightDevolution).toFixed(4);
 
			this.AcceptedToleranceInput = 0;
			this.AcceptedToleranceInputDiscart = this.lotReception.AcceptedToleranceInputDiscart ?? 0;
			this.getCalcule(); 
		}
	}
</script>